@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono&display=swap');

body {
  font-family: 'Ubuntu', sans-serif !important;
}

// Spacing helpers
$space-values: (0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 72) !default;

$space-prefixes: (
  p: padding,
  pt: padding-top,
  pr: padding-right,
  pb: padding-bottom,
  pl: padding-left,
  m: margin,
  mt: margin-top,
  mr: margin-right,
  mb: margin-bottom,
  ml: margin-left,
) !default;

@mixin make-spaces() {
  @each $attr-short, $attr-long in $space-prefixes {
    @each $value in $space-values {
      .#{$attr-short}-#{$value} {
        #{$attr-long}: #{$value}#{'px'} !important;
      }
    }
  }
}

@include make-spaces();

// ARAGON OVERRIDES

span[class^='Switch_'] {
  z-index: 0 !important;
}

button {
  border-radius: 10px;
  &::after {
    border-radius: 10px !important;
  }
}

.bNEUIQ[style='height: 367px;'] + .bWAZMD {
  height: fit-content !important;
}

// CLASS HELPERS

.center {
  text-align: center !important;
}

.hide {
  display: none;
}

.show {
  display: unset;
}

.collapsed {
  visibility: collapse;
}

.block {
  width: 100% !important;
  display: block !important;
}

.flex-center {
  display: flex !important;
  justify-content: center !important;
}

.fit-content {
  width: fit-content !important;
}

.inline-flex {
  display: inline-flex !important;
}

.pointer {
  cursor: pointer;
}

.btn-no-margin {
  button {
    margin-left: 0 !important;
  }
}

.btn-link button {
  padding: 0;
  margin-top: 8px;
  background: transparent;
  border: none;
  box-shadow: none;
  color: dodgerblue;
}

.wide {
  width: 100%;
}

.max-width-100 {
  max-width: 100%;
}

.flex-grow {
  flex-grow: 1;
}

/* custom scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: #4a4a4a #00000000;
}
*::-webkit-scrollbar {
  width: 20px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
*::-webkit-scrollbar-corner {
  background-color: #00000000;
}

button:focus:after {
  margin: -4px !important;
}

button.khyxRL {
  justify-content: center;
}
